<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="1000px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            :label="__('Assign Summary to Variable')"
            prop="summary_var"
            class="is-required"
          >
            <create-or-select
              :items="singleValuedVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :current_select="selectedVariableForSummary"
              @change="handleVariableChange($event)(summaryVariableRuleValue)"
            />
            <el-col :span="4">
              <span
                class="row-message"
                v-if="isNewVariableCreatedToStoreSummary"
              >
                {{ __("new variable") }}
              </span>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding-top: 15px">
        <el-col :span="24">
          <el-form-item
            :label="__('Assign Conversation Transcript to Variable')"
          >
            <create-or-select
              :items="singleValuedVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :current_select="selectedVariableForTranscript"
              @change="
                handleVariableChange($event)(transcriptVariableRuleValue)
              "
            />
            <el-col :span="4">
              <span
                class="row-message"
                v-if="isNewVariableCreatedToStoreTranscript"
              >
                {{ __("new variable") }}
              </span>
            </el-col>
          </el-form-item>
          <el-form-item :label="__('Summary Language')">
            <input-variable-popper
              :value="
                nodeToBind.summarization_node.data.summary_language ||
                  'English US'
              "
              @input="updateSummaryLanguage"
              :is-text-area="false"
              is-auto-complete-input
              :autocomplete-list="autocompleteLanguageList"
              :placeholder="__('Select Summary Language')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import { mapGetters } from "vuex";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper.vue";
import { aiSummaryLanguageList } from "@/constants/openFormType";
const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};
export default {
  mixins: [BaseNode],
  components: {
    InputVariablePopper,
    CreateOrSelect
  },
  data() {
    const validateSummaryField = async (rule, value, callback) => {
      if (!this.selectedVariableForSummary) {
        callback(new Error(__("Summary Variable Required")));
      }
      callback();
    };

    return {
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      summaryVariableRuleValue: "open_ai_summary_var",
      transcriptVariableRuleValue: "open_ai_transcript_var",
      autocompleteLanguageList: aiSummaryLanguageList,
      rules: {
        summary_var: {
          validator: validateSummaryField,
          trigger: "change"
        }
      }
    };
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedVariables: "singleValuedVariables"
    }),
    /**
     * returns selected variable_id if selected from the dropdown or the name if it's a new variable for summary field
     * @return string|number
     */
    selectedVariableForSummary() {
      let variableRule = this.insertedSummaryVariableRule;
      const { variable_id, variable_name } = _.isEmpty(variableRule)
        ? this.variableRuleInitialize
        : variableRule;
      return variable_id === -1 ? variable_name : variable_id;
    },
    /**
     * returns selected variable_id if selected from the dropdown or the name if it's a new variable for transcript field
     * @return string|number
     */
    selectedVariableForTranscript() {
      let variableRule = this.insertedTranscriptVariableRule;
      const { variable_id, variable_name } = _.isEmpty(variableRule)
        ? this.variableRuleInitialize
        : variableRule;
      return variable_id === -1 ? variable_name : variable_id;
    },
    /**
     * returns selected variable rule for summary variable rule if set, otherwise returns initial variable rule structure
     * @return object
     */
    insertedSummaryVariableRule() {
      return (
        _.find(
          this.nodeToBind.summarization_node.data.variable_rules.data,
          rule => rule.rule_value === this.summaryVariableRuleValue
        ) || this.variableRuleInitialize
      );
    },
    /**
     * returns selected variable rule for transcript variable rule if set, otherwise returns initial variable rule structure
     * @return object
     */
    insertedTranscriptVariableRule() {
      return (
        _.find(
          this.nodeToBind.summarization_node.data.variable_rules.data,
          rule => rule.rule_value === this.transcriptVariableRuleValue
        ) || this.variableRuleInitialize
      );
    },
    isNewVariableCreatedToStoreSummary() {
      if (_.isEmpty(this.insertedSummaryVariableRule)) {
        return false;
      }
      return _.get(this.insertedSummaryVariableRule, "msg", "") !== "";
    },
    isNewVariableCreatedToStoreTranscript() {
      if (_.isEmpty(this.insertedTranscriptVariableRule)) {
        return false;
      }
      return _.get(this.insertedTranscriptVariableRule, "msg", "") !== "";
    }
  },
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },
    updateSummaryLanguage(language) {
      this.$set(
        this.nodeToBind.summarization_node.data,
        "summary_language",
        language
      );
    },
    /**
     * method to push passed rule_value(summary and transcript) with respective assigned variable
     * to variable_rules data for the node
     */
    handleVariableChange(option) {
      return rule_value => {
        this.newVariableCreated = option.value === -1;
        const insertedRowIdToVariableObj = {
          variable_id: option.value,
          variable_name: option.label,
          msg: option.msg,
          rule_value: rule_value,
          array_variable: false
        };
        if (_.isEmpty(this.nodeToBind.summarization_node.data.variable_rules)) {
          this.$set(
            this.nodeToBind.summarization_node.data,
            "variable_rules",
            {}
          );
          this.$set(
            this.nodeToBind.summarization_node.data.variable_rules,
            "data",
            []
          );
        } else {
          _.remove(
            this.nodeToBind.summarization_node.data.variable_rules.data,
            obj => obj.rule_value === rule_value
          );
        }
        this.nodeToBind.summarization_node.data.variable_rules.data.push(
          insertedRowIdToVariableObj
        );
      };
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.summarization_node)
    ) {
      this.$set(this.nodeToBind, "summarization_node", {});
      this.$set(this.nodeToBind.summarization_node, "data", {});

      if (_.isEmpty(this.nodeToBind.summarization_node.data.variable_rules)) {
        this.$set(
          this.nodeToBind.summarization_node.data,
          "variable_rules",
          {}
        );
      }
      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.SUMMARIZATION.NODE_TYPE
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/element-variables.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.el-form-item:last-of-type {
  margin-bottom: 10px;
}
</style>
